import { BackendBase } from './backend_base.js';

export class BackendSubscriberBase extends BackendBase {
  _safeCall(cb, ...args) {
    try {
      cb(...args);
    } catch (err) {
      this.log('Error generated by subscriber.\n');
    }
  }

  _receive(path, value) {
    const q = this._subscribers.get(path);

    if (q === void 0) return;

    if (typeof q === 'function') {
      this._safeCall(q, 1, 0, value);
    } else {
      for (let i = 0; i < q.length; i++) {
        this._safeCall(q[i], 1, 0, value);
      }
    }
  }

  _hasSubscribers(path) {
    return this._subscribers.has(path);
  }

  constructor(options) {
    super(options);
    this._subscribers = new Map();
  }

  observeByPath(path, callback) {
    const subscribers = this._subscribers;
    let q = subscribers.get(path);

    if (Array.isArray(q)) {
      if (q.includes(callback)) throw new Error('Already subscribed.');
      q.push(callback);
    } else if (typeof q === 'function') {
      if (callback === q) throw new Error('Already subscribed.');
      q = [q, callback];
    } else {
      q = callback;
    }

    subscribers.set(path, q);

    return () => {
      let q = subscribers.get(path);

      if (Array.isArray(q)) {
        q = q.filter((_cb) => _cb !== callback);
        if (q.length === 1) q = q[0];

        subscribers.set(path, q);
      } else if (typeof q === 'function') {
        subscribers.delete(path);
      }
    };
  }
}
